.create-project-modal {
  .simple-input {
    margin-top: 1.6rem;
  }

  .simple-input_error {
    top: 6.8rem;
  }

  .button {
    margin-top: 3.2rem;
    margin-left: auto;
  }
}