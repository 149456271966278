.file-upload-error-info {
  border-radius: 0.8rem;
  text-align: center;
  opacity: 0;

  &.visible {
    padding: 1.6rem;
    opacity: 1;
  }

  &.red {
    background-color: rgba(253, 234, 234, 1);
    color: rgba(214, 85, 85, 1);
  }

  &.yellow {
    color: rgba(197, 142, 0, 1);
    background-color: rgba(251, 241, 216, 1);
  }
}