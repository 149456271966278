%icon {
  width: 1.8rem;
  height: 1.8rem;
  background-size: cover;
  background-position: center;
}

.icon {
  &_search {
    @extend %icon;
    background-image: url('../assets/icons/search.svg')
  }
  &_gear {
    @extend %icon;
    background-image: url('../assets/icons/settings.svg')
  }
}