.empty-project-user-table-placeholder {
  padding: 2.8rem 0;
  margin: 1.6rem 3.2rem 3.2rem;

  border-radius: 0.8rem;
  border: 1px solid #E2E2E2;

  text-align: center;
  color: #949494;
  line-height: 2.4rem;
}