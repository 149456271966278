@import '../../../../../styles/variables';
@import '../../../../../styles/mixins.scss';

.left-sidebar-projects-popup {
  background: #FFFFFF;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  max-width: 100%;
  width: 24.7rem;
  position: absolute;
  z-index: 2001;
  overflow: hidden;
  bottom: 19rem;
  left: 1.5rem;
  transition: all 0.3s;

  &.hidden {
    height: 0;
  }

  &.close {
    bottom: 22.9rem;
    left: 0.6rem;
  }

  .new-project_btn {
    margin-bottom: 0.8rem;
    justify-content: flex-start;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  &-list {
    max-height: calc(70vh * 1.25);
    overflow-y: auto;
    margin-top: 0.8rem;

    &::-webkit-scrollbar {
      width: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
      background-color: #F2F2F2;
    }

    .projects-invites-counts {
      color: #D3461A;
      position: relative;
      margin-left: 4.8rem;
      line-height: 2.4rem;
      font-family: "Nunito Sans";
      font-weight: 400;
    }

    .project-invite {
      font-family: "Nunito Sans";
      position: relative;
      font-weight: 800;
      line-height: 2.4rem;
      color: #949494;
      margin-left: 0.8rem;
      margin-right: 0.8rem;
      border-radius: 0.8rem;
      padding: 1.6rem 0 1.6rem 4.8rem;

      &:hover {
        cursor: pointer;
        background-color: $light-gray-some-bg;
      }
    }

    .project-invite, .projects-invites-counts {
      &::before {
        content: '';
        height: 0.8rem;
        position: absolute;
        width: 0.8rem;
        background-color: #D3461A;
        border-radius: 50%;
        top: 50%;
        left: -2.4rem;
        transform: translate(-50%, -50%);
      }
    }

    .project-invite {
      &::before {
        left: 1.6rem;
      }
    }
    
    &__item {
      margin:  0.8rem;
      padding: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Nunito Sans';
      font-weight: 800;
      line-height: 2.4rem;
      color: #404040;

      .project-title {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        background-color: $light-gray-some-bg;
      }

      .icon {
        &:hover {
          cursor: pointer;
        }
        path {
          fill: #1a65da;
          fill-opacity: 1;
        }
      }

      &.active {
        background-color: #E8F1FB;
        border-radius: 0.8rem;
        color: #1A65DA;
      }
    }
  }
}