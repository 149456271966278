.file-chip {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 1.3rem 1.6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .section {
    display: flex;
  }

  .control-wrapper {
    margin-left: 1.2rem;
  }
}
