.role-chip-matrix {
  height: 100%;
  width: 100%;


  display: flex;
  align-items: center;
  justify-content: center;

  &.true-lock {
    background-color: #DCF9E9;
  }

  &.false-lock {
    background-color: #F2F2F2;
  }

  .icon-with-lock {
    position: relative;

    svg:nth-child(2) {
      position: absolute;
      right: -0.46rem;
      bottom: 0.05rem;
    }
  }
}