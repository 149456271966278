@import 'fonts';
@import 'variables';
@import 'icons';

*, *::before, *::after {
  box-sizing: initial;
}

html {
  height: 100%;
  font-size: 62.5%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat Medium';
  color: $black;
  font-size: $font-size_xs;
  line-height: $font-size_s;
  height: 100%;
  overflow: hidden;
}

p {
  margin: initial;
}

.main-layout {
  transform: scale(0.8);
  transform-origin: top left;
  height: 125vh;
  width: 125vw;
}

.modal-bg {
  transform: scale(0.8);
  transform-origin: top left;
  height: 125vh;
  width: 125vw;
}

h1,h2,h3 {
  margin: 0;
  color: inherit;
}

h1 {
  font-family: 'Nunito Sans';
  line-height: $line-height-h1;
  font-size: $font-size-h1;
  font-weight: bold;
  color: $black-bold;
}

h2 {
  font-family: 'Nunito Sans';
  font-size: $font-size_l;
  line-height: 2.8rem;
  font-weight: bold;
  color: $black-bold;
}

h3 {
  font-family: 'Nunito Sans';
  font-size: $font-size_m;
  line-height: 2.4rem;
  font-weight: bold;
}

div {
  box-sizing: border-box;
}

.text {
  &_inactive {
    color: $gray;
  }
}

.text_bold {
  font: {
    family: 'Montserrat Bold';
  }
}

table {
  border: 0;
  td {
    border: 0;
  }
  th {
    border: 0;
  }
  tr {
    border: 0;
  }
}

a {
  text-decoration: none;
  color: $violet;
  cursor: pointer;
  font-family: 'Montserrat Bold';
  &:hover {
    color: inherit;
  }
}

a.tag{
  color:$violet;
}
a.tag:hover{
  color:$violet;
}

.icon_hovered {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  &:hover{
    background-color: $light-gray-some-bg;
  }
}

.gradient-loading-block {
  opacity: 0.5;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load 1.5s infinite;
}

@keyframes load {
  100% {
    background-position: -100% 0;
  }
}

.btn{
  cursor: pointer;
  z-index: 2;
  &_hover{
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_hover::after{
    content: '';
    position: absolute;
    width: 100%;
    min-width: 2.4rem;
    height: 2.4rem;
    padding: 1.3rem;
    background-color: $light-gray-some-bg;
    border-radius: 50%;
    opacity: 0;
    z-index: -1;
  }
  &_hover:hover {
    &::after {
      opacity: 1;
    }
  }
}

::-webkit-scrollbar { 
  width: 1rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb { 
  border-radius: 4rem;
  background-color: $dark-gray;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 55%;
  }
  $font-size_xxs: 1rem;
  $font-size_xs: 1.4rem;
  $font-size_s: 1.6rem;
  $font-size_m: 1.8rem;
  $font-size_l: 2.2rem;
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 47%;
  }
}

@media screen and (max-width: 640px) {
  html {
    font-size: 62.5%;
    height: 100%;
  }
}