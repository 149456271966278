@mixin row {
    display: flex;
}

@mixin row-x-center {
    display: flex;
    justify-content: center;
}

@mixin row-y-center {
    display: flex;
    align-items: center;
}

@mixin row-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin row-between {
    display: flex;
    justify-content: space-between;
}

@mixin col {
    display: flex;
    flex-direction: column;
}

@mixin col-reverse {
    display: flex;
    flex-direction: column-reverse;
}

@mixin col-x-center {
    @include col;
    align-items: center;
}

@mixin col-y-center {
    @include col;
    justify-content: center;
}

// ANIMATION

@mixin transition-all {
    transition: all 0.2s;
}

@mixin opacity-off {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
}

@mixin opacity-on {
    opacity: 1;
    visibility: visible;
}
