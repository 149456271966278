@import "../../../../\(deprecated\)/styles/mixins.scss";

.loader.spinner-loader {
  @include row-center();
  width: 2.4rem;
  height: 2.4rem;

  .spinner {
    border: 2px solid #1a65da;
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;

    transform: translateZ(0);
    animation: spin 1.5s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}