@import '../../../styles/variables';

.avatar {
  display: flex;
  align-items: flex-end;
  background-color: $gray; 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: relative;
  &_size {
    &-xs {
      height: 2.4rem;
      min-width: 2.4rem;
      width: 2.4rem;
      min-height: 2.4rem;
    }
    &-s {
      height: 3.2rem;
      min-width: 3.2rem;
      width: 3.2rem;
      min-height: 3.2rem;
    }
    &-sm{
      height: 4.8rem;
      min-width: 4.8rem;
      width: 4.8rem;
      min-height: 4.8rem;
    }
    &-m {
      height: 6.4rem;
      min-width: 6.4rem;
      width: 6.4rem;
      min-height: 6.4rem;
    }
    &-l {
      height: 12.4rem;
      min-width: 12.4rem;
      width: 12.4rem;
      min-height: 12.4rem;
    }
    &-xl {
      height: 18rem;
      min-width: 18rem;
      width: 18rem;
      min-height: 18rem;
    }
  }
  &_square {
    border-radius: 0;
  }
  input[type='file'] {
    visibility: hidden;
    position: absolute;
  }
  &__upload-btn {
    width: 100%;
    background-color: $black;
    opacity: 0.48;
    border-radius: 0;
    padding: 0;
    height: 3rem;
    border: none;
    .button_icon {
      margin: 0;
      justify-content: center;
    }
    &:hover {
      background: $black;
      opacity: 0.6;
      border: none
    }
  }
}