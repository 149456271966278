  .footer-btn-row {
    display: flex;
    margin-left: auto;
    margin-top: 1.6rem;

    .button {
      margin-left: 1.6rem;
    }

    .button_blue-ligth {
      margin-left: auto;
    }
  }