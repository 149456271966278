.wide-exclude-users-from-project {

  &__modal {

    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    &_header {
      display: flex;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-right: auto;
      }
    }

    &_content {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 3.2rem;
    }

    &_btns {
      display: flex;
      margin-left: auto;

      .button {
        margin-left: 1.6rem;
      }
    }
  }
}