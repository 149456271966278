.file-chip {
  transition: all 0.3s ease;
  height: auto;
  width: 100%;

  &:has(.remove-file-icon.removed) {
    opacity: 0;
    padding: 0;
    scale: 0;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;

    .section {
      overflow: hidden;
      transition: all 0.1s ease;
      opacity: 0;
    }
  }

  .section:first-child {
    display: flex;
    align-items: center;
    width: 80%;

    .icon-wrapper {
      margin-right: 1.2rem;
    }

    .file-chip-icon {
      height: 2.4rem;
    }

    .title {
      color: #404040;
      font-size: 1.6rem;
      line-height: 2.4rem;
      hyphens: auto;
      word-wrap: break-word;
      max-width: 90%;
    }
  }

  .description-wrapper {
    margin-right: 1.2rem;
  }

  .control-wrapper {
    cursor: pointer;
  }

  .loader.spinner-loader .spinner {
    border-top-color: #f2f2f2;
  }
}