 @import '../../../styles/variables.scss';
 
 .pop-up__wrapper {
   position: absolute;
   border: $border-bottom;
   box-shadow: $boxShadow;
   background-color: $white;
   z-index: 9000;
   padding: $indent_m;
   border-radius: $borderRadius;
 }
