.right-sidebar-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 125vh;
  background-color: #FFFFFF;
  padding-top: 4rem;
  width: 8rem;
  margin-left: auto;
  border-left: 1px solid #F2F2F2;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease-in-out;
  z-index: 2000;


  .sidebar-open-btn {
    cursor: pointer;
    transform: rotate(-90deg);
    margin-bottom: 4rem;
    z-index: 3000;
    width: 3.2rem;
    height: 3.2rem;
    background-color: #E8F1FB;
    border-radius: 50%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    path {
      fill: #1A65DA;
    }
  }

  .notification-icon {
    position: relative;
    z-index: 2;
  }

  .sidebar_top__notification-count {
    color: #FFFFFF;
    background-color: #D65555;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-family: "Nunito Sans";
    font-weight: 700;
    position: absolute;
    right: 1.2rem;
    top: -0.8rem;
    opacity: 1;
    transition: opacity 3ms;
  }
}

.tool-tip-of-right-sidebar {
  right: 8rem;
  left: auto;
}

.right-sidebar-wrapper.closed {
  width: 0;

  .sidebar-open-btn {
    transform: rotate(90deg) translateY(1.6rem);
    background-color: #1A65DA;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    path {
      fill: white;
    }
  }

  .right-sidebar-card-link {
    opacity: 0;
    pointer-events: none;
  }
}

.right-sidebar-card-link {
  height: 6.4rem;
  width: 6.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  transition: all 0.3s;
  
  &:hover {
    background-color: #F2F2F2;
    border-radius: 1rem;
    cursor: pointer;
  }

  &.active {
    background-color: #E8F1FB;

    path {
      fill: #1a65da;
      fill-opacity: 1;
    }
  }
}

.bottom-links {
  margin-top: auto;
  margin-bottom: 5rem;

  .sidebar_top__notification-count {
    z-index: 0;
    background-color: #1a65da;
  }
}