@import "../../../../fsd/shared/style/variables.scss";
@import "../../../styles/mixins.scss";

.s-i-wrapper {
  width: 100%;
  height: fit-content;
  @include col();
  position: relative;

  &:has(.simple-input_error) {
    .simple-input.border {
      border-color: $dark-red;
    }
  }
}

.simple-input {
  @include row-y-center();
  @include transition-all();
  height: 5rem;
  border-radius: 0.6rem;
  padding: 1.3rem 1.6rem;
  border: 0.1rem solid transparent;
  position: relative;

  input {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    background-color: transparent;
  }

  &_svg {
    @include row-y-center();

    &-left {
      margin-right: 1.2rem;
    }

    &-right {
      margin-left: auto;
    }
  }

  &_placeholder {
    position: absolute;
    transition: opacity 0.1s;
    padding: 0 0.2rem;
    margin-left: -0.2rem;
    color: $gray6;
  }

  &_error {
    position: absolute;
    color: $dark-red;
    top: 4.9rem;
    left: 1.2rem;
    font-size: $font-size_xxs;
    line-height: $font-size_xs;
    z-index: 1;
  }

  &:hover {
    background-color: $light-violet;
  }

  &.focus {
    background-color: white !important;
  }

  &.focus .simple-input_placeholder,
  &.not-empty .simple-input_placeholder {
    opacity: 0;
    visibility: hidden;
  }

  &.border {
    border: 0.1rem solid $gray4;
  }

  &.blue-border-focus.focus {
    border: 0.1rem solid $violet;
  }

  &.gray-background {
    background-color: $light-violet;
    &.not-empty {
      background-color: $lite-gray;
    }

    &:not(.disabled):hover {
      background-color: $gray3;
    }
  }

  &.top-placeholder {
    .simple-input_placeholder {
      transition: all 0.2s;
    }

    &.focus .simple-input_placeholder,
    &.not-empty .simple-input_placeholder {
      opacity: 1;
      visibility: visible;
      transform: translateY(-2.51rem);
      left: 1.6rem !important;
      font-size: 1.2rem;
      background-color: white;
    }
  }
}
