@import '../../style/variables';

.dynamic-pagination{
  display: flex;
  flex-direction: column;
  height: 100%;
  &__btn-control{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: $indent_m;
    p{
      color: $gray6;
    }
    svg{
      margin-left: $indent_m;
      transition: all 0.2s;
      cursor: pointer;
      &:last-child{
        transform: rotate(180deg);
      }
      &.disable{
        opacity: 0.3;
      }
    }
  }
  .info-tooltip{
    margin-top:12rem;
    left: 3rem;
  }
}