.settings-project-role-access-subject-bar {
  display: flex;

  .block {
    display: flex;

    &_access-subjects {
      display: flex;
      flex-direction: column;
    }
  }

  .modules {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 4rem;
      flex-shrink: 0;
      background-color: #f2f2f2;
      // border-bottom: 0.1rem solid #E2E2E2;

      border-top: 0.1rem solid #e2e2e2;

      p {
        writing-mode: vertical-rl;
        transform: scale(-1);
      }

      &.docs {
        border-top-left-radius: 0.8rem;
      }

      &.project {
        border-bottom-left-radius: 0.8rem;
        border-bottom: none;
      }

      &.other {
        opacity: 0;
      }
    }
  }

  .module-icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 0.4rem;
    transform: rotate(270deg);
  }

  .module-title {
    color: #5c5c5c;
  }

  .access-subjects {
    width: 24rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    &__item {
      padding: 2rem 1.2rem;
      border-top: 0.1rem solid #e2e2e2;

      &:last-child {
        border-bottom: 0.1rem solid #e2e2e2;
      }

      p {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #404040;
      }
    }
  }
}
