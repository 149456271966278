@import '../../../../../styles/variables';

.tabs_wrapper .wrapper__card-link-tab {
  .card-link_active {
    background-color: $blue-ligth-2 !important;
  }
}

.wrapper__card-link {
  position:relative;
  display: block;
  margin-bottom: 1.6rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: transparent;
  }

  .card-link__icon {
    z-index: 2;
  }

  .card-link__icon {
    &.active-icon path {
      fill: #1A65DA;
      fill-opacity: 1;
    }
    &.disabled-icon path {
      fill: #000000;
      fill-opacity: 0.64;
    }
  }


  .card-link__icon:hover ~ .card-link {
    background-color: $light-gray-some-bg;
  }

  .card-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $black-bold;
    height: 6.8rem;
    border-radius: 0.8rem;
    position: relative;
    &__icon {
      cursor: pointer;
      text-align: center; 
    } 
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 70%;
    }
    &:hover {
      background-color: $light-gray-some-bg;
    }
    &_hidden{
      display: none;
    }
    &_active {
      background-color: $light-blue !important;
      color: $violet;
      .card-link__icon {
        path {
          fill: $violet;
          fill-opacity: 1;
        }
      }
      path {
        fill-opacity: 1;
      }
      span {
        color: $violet !important;
      }
      &:hover {
        background-color: $light-blue;
      }
    }

    &_disabled {
      background-color: transparent !important;
      color: $black-bold;

      span {
        color: rgba(0, 0, 0, 0.8) !important;
      }
    }
    .chevron{
      margin-right: $indent_xs;
      transition: all 0.3s;
    }
    .active-tabs{
      transform: rotate(180deg);
    }
    &_count-toification{
      position: absolute;
      top:50%;
      right: 0.8rem;
      transform: translateY(-50%);
      width: 2.6rem;
      height: 2.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: $violet;
      border-radius: 50%;
      &-big-number {
        font-size: $indent_ssm;
      }
    }
  }
}

.tabs_wrapper{
  position: relative;
  background-color: $light-violet;
  border-radius: 1rem;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s;

  .wrapper__card-link-tab:nth-child(1) {
    .card-link {
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
  } 

  .wrapper__card-link-tab:nth-last-child(1) {
    .card-link {
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
  }

  .wrapper__card-link-tab {
    margin-bottom: 0;
    .card-link {
      border-radius: 0;
      margin: 0;
      &:hover {
        background-color: $light-gray-some-bg;
      }
      &_active {
        background-color: $light-blue;
        color: $violet;
        .card-link__icon {
          path {
            fill: $violet;
            fill-opacity: 1;
          }
        }
        path {
          fill: $violet;
          fill-opacity: 1;
        }
        span {
          color: $violet !important;
        }
        &:hover {
          background-color: $hover-light-blue;
        }
      }
    }
  }

  &__active{
    display: block !important;
    margin-top: 1.6rem;
    -webkit-transition: all 0.5s;
  }
}
.folded-icon{
  position: absolute;
  top: 5.2rem;
  left: 1.8rem;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $light-gray-some-bg;
  transition: all 0.3s ease-in-out;
  transform: rotateZ(90deg);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &_hidden{
    opacity: 0;
    left: 1.45rem;
  }
  &_open{
    transform: rotatez(-90deg);
  }
  path:first-child{
    fill: $dark-gray;
  }
}