@import '../../../../(deprecated)/styles/variables.scss';

.banner-download-modal{
  margin: $indent_s;
  // margin-bottom: 12%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed !important;
  border-radius: $veryBigBorderRadius;
  overflow: hidden;
  position: relative;
  padding: 0;
  .modal-card__header{
    display: none;
  }
}
.banner-download{
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top-block{
    flex-basis: 85%;
    position: relative;
    display: flex;
    flex-direction: column;
    &_background-svg{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top:0;
    // z-index: 8000;
    }
    &__main{
      position: absolute;
      z-index: 8001;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      padding: 0 $indent_m;
      padding-bottom: 2.7rem;
      &_svg-close{
        position: absolute;
        right: 3.2rem;
        top: 3.2rem;
        z-index: 8002;
        svg path{
          fill: white;
        }
      }
      &_svg-wrapper{
        width: 16.1rem;
        height: 16.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 2rem;
        border-radius: 3rem;
        background-color: white;
        align-self: center;
        margin-top:11.2rem
      }
      &_text-wrapper{
        margin-top: 4.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
          width: 100%;
          color: white;
          text-align: center;
          margin-bottom: $indent_m;
        }
        p{
          color:white;
          text-align: center;
          font-size: $font-size-mobile_s;
          max-width: 38.1rem;
        }
      }
      &_icons-wrapper{
        display: flex;
        justify-content: center;
        margin-top: auto;
        svg:first-child{
          margin-right: $indent_xs;
        }
      }
    }
  }
  &__bottom-block{
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    .button{
      flex-basis: 80%;
      height: 5rem;
      border-radius: $borderRadius;
      max-width: 52.1rem;
    }
  }
  &__turn-over{
    display: flex;
    flex-direction: column;
    height: 100%;
    &__top-block{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      // margin-top: 5%;
      padding-top: $indent_xl;
      p{
        margin-top: $indent_s;
      }
      svg{
        margin-bottom: 5.2rem;
      }
    }
    &__middle-block{
      flex-basis: 50%;
      width: 100%;
      background-image: url('../../../../(deprecated)/assets/images/rotate-screen-animation.gif');
      background-size: cover;
      background-position:bottom;
    }
  }
}


