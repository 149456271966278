@import '../../style/variables';

.card__wrapper {
  background-color: $white;
  border-radius: 2rem;
  padding: $indent_m;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.02);
}
