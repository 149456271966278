@import '../../../styles/variables.scss';

.modal {
  &-bg {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    backdrop-filter: blur(0.5rem);
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    &_hidden {
      opacity: 0;
      z-index: -1;
    }
  }
  &-card {
    padding: $indent_m;
    border-radius: $borderRadius;
    margin: auto;
    background-color: $white;
    position: relative;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    &__header{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: $indent_m;
      h2{
        margin-right: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 2.8rem  
      }
      // h2 {
      //   margin-bottom: 3.8rem;
      //   width: calc(100% - 2rem);
      //   margin-right: 0.8rem;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   min-height: 2.8rem;
      // }
    }
  }
}