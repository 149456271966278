.collapse-with-title {
  min-height: 5.6rem;
  display: flex;
  flex-direction: column;
  // border: 0.1rem solid #E2E2E2;
  // border-radius: 0.8rem;
  padding: 1.6rem;

  overflow: hidden;

  transition: all 0.3s;

  &.open {
    svg {
      transform: rotate(180deg);
    }

    .collapse-with-title__items {
      margin-top: 2rem;
      opacity: 1;
    }
  }

  svg {
    transition: all 0.3s;
  }

  &__header {
    display: flex;
    cursor: pointer;


    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-right: auto;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    opacity: 0;
    overflow: hidden;
  }
}

.collapse-with-title__items-transition{
  transition: all 0.3s;
}

