@import '../../../../styles/variables';

.wrapper-sidebar {
  width: 8rem;
  min-width: 8rem;
  transition: all 0.5s;
  // overflow-y: scroll;
  &__open-btn {
    z-index: 3000;
    width: 3.2rem;
    height: 3.2rem;
    background-color: $blue-ligth;
    border-radius: 50%;
    position: absolute;
    top: 3.3rem;
    left: 6.2rem;
    transition: all 0.5s;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(-90deg);
    svg path {
      fill: $violet;
    }
  }
  
  &_open {
    width: 28rem;
    min-width: 28rem;
  
    .wrapper-sidebar__open-btn {
      transform: rotateZ(90deg);
      left: 23rem;
    }
  }
}