#help-desk {
  cursor: pointer;
  right: 1.6rem;
  height: 100%;
}

#help-desk__button {
  width: 4.8rem;
  height: 100%;
  text-align: center;
  line-height: 50px;
  background-image: url('../../shared/assets/icons/helpdesk-chats.svg');
  background-color: #1a65da;
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 3rem;
  color: white;
  cursor: pointer;
  user-select: none;
  outline: none;
}

#hde-container {
  left: 12.4rem !important;
  bottom: 5.8rem !important;
  width: 375px !important;
  transform: scale(1.25);
}

#hde-chat-container {
  width: 360px !important;
}