@import '../../../styles/variables';

.notification {
  display: flex;
  align-items: center;
  max-width: 38.4rem;
  background-color: $notificationColor;
  box-shadow: $notificationBoxShadow;
  border-radius: $borderRadius;
  padding: $indent_s;
  color: $white;
  min-width: inherit !important;
  &__title {
    margin-bottom: $indent_s;
    font-size: 1.92rem;
    line-height: 2.24rem;
    color: $white;
  }
  &__message {
    font-size: 1.28rem;
    line-height: 1.44rem;
  }
  &__main-info {
    margin-right: $indent_s;
    &_no-margin {
      margin-right: 0;
    }
  }
  &__btn-group {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    &_top {
      margin-top: $indent_s;
    }
    button {
      padding: 0;
      margin: 0;
      height: auto;
      color: $dark-blue;
      font-size: 1.28rem;
      line-height: 1.44rem;
      min-height: 1.44rem;
      &:not(:last-child) {
        margin-right: $indent_s;
      }
    }
  }
}

.makeStyles-root-1 {
  min-width: inherit !important;
}
.MuiCollapse-wrapperInner {
  width: auto !important;
}
.MuiCollapse-wrapper {
  justify-content: flex-end;
}