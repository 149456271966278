@import '../../style/variables.scss';

.checkbox {
  box-sizing: border-box;
  &__field {
    visibility: hidden;
    position: absolute;
    &:checked + .checkbox__label{
      &::before {
        background-color: $violet;
        border-color: $violet;
        background-image: url('../../../../\(deprecated\)/assets/icons/checked.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150%;
      }
    }
    &:checked + .checkbox__label.minus{
      &::before {
        background-color: $violet;
        border-color: $violet;
        background-image: url('../../../../\(deprecated\)/assets/icons/checked-minus.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120%;
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      width: 1.8rem;
      min-width: 1.8rem;
      height: 1.8rem;
      box-sizing: border-box;
      border-radius: $borderRadiusButton;
      border: 0.2rem solid $border-color;
      margin-right: $indent_s;
      transition: background-color 0.5s;
    }
    &_disabled {
      &::before {
        margin-right: 0;
      }
    }
  }
  &-spaced {
    padding: 0 1.6rem;
    margin: 2rem 0 1rem;
    background-color: transparent;
    &_alert {
      position: relative;
      .checkbox__field:checked + .checkbox__label::before {
        background-color: $red;
        border-color: $red;
      }
      &::after {
        content: '';
        z-index: -1;
        display: block;
        height: 5rem;
        width: 100%;
        position: absolute;
        top: -1.3rem;
        left: 0;
        border-radius: 0.5rem;
        background-color: $light-red;
      }
    }
  }
}