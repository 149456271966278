.project-invitation-modal {
  width: 57rem !important;
  font-family: "Nunito Sans";
  line-height: 2.4rem;
  visibility: visible;

  &.hidden {
    visibility: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;

    h2 {
      font-family: "Nunito Sans";
      font-weight: 800;
    }
  }


  .invitation-message {
    margin-bottom: 1.6rem;

    .invitation-project-name {
      font-weight: 800;
    }
  }

  &-btns-control {
    margin-top: 3.2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;
  }

}