.settings-project-role__participants {
  display: flex;
  flex-direction: column;

  .title,.all-count,.count-my-project {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #404040;
    font-weight: 400;
  }

  .title{
    font-weight: 700;
    margin-bottom: 0.2rem;
  }

}