.add-company-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.2rem;

  .title {
    font-weight: 800;
    line-height: 2.8rem;
  }
}