.left-sidebar-tooltip-hover-notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 1rem;
  z-index: 100000;
  left: 8rem;
  opacity: 1;
  padding: 1.6rem;
  background-color: white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  white-space: nowrap;
  word-break: keep-all;
  color: #5C5C5C;
  height: 5.6rem;
  transform: scale(1);
  transition: all 0.3s;

  &.disable {
    transform: scale(0);
  }
}