.user-in-table-action-card_popup {
  position: relative;

  .options {
    display: flex;
    flex-direction: column;
    width: 24rem;

    background-color: white;
    box-shadow: 0px 0.4rem 2.4rem rgba(31, 74, 113, 0.11);
    border-radius: 0.6rem;

    &_item {
      height: 4.8rem;
      display: flex;
      align-items: center;

      padding: 1.2rem 1.6rem;

      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;

      color: #404040;

      transition: all 0.2s;

      cursor: pointer;

      p {
        margin-left: 1rem;
      }

      &:hover {
        background: #f2f2f2;
      }

      &.exclude-btn {
        color: rgba(26, 101, 218, 1);
        opacity: 1;
        height: 5.2rem;
        border-top: 0.1rem solid #e2e2e2;

        svg path {
          fill: rgba(26, 101, 218, 1);
        }
      }
    }
  }
}
