.drag-and-drop {
  .dnd-wrapper {
    width: 100%;
    padding: 3.6rem 3.2rem;
    border-radius: 4px;
    border: 2px dashed rgba(26, 101, 218, 0.5);
    cursor: pointer;

    font-family: "Nunito Sans";
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #1a65da;
  }

  .bold-text {
    font-weight: 700;
  }

  .description {
    font-size: 1.4rem;
    color: #949494;
    line-height: 2rem;
    margin-top: 0.8rem;
  }

  p {
    text-align: center;
  }
}
