.sidebar__nav-links {
  margin-top: 3.2rem;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70%;

  &::-webkit-scrollbar  {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  &__scrolling {
    border-top: 1px solid #E2E2E2;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;

  .card-link {
    span {
      font-family: 'Nunito Sans';
      font-weight: 800;
      color: rgba(0, 0, 0, 0.64);
    }
  }
}