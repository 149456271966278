// DEPRECATED

//Colors
$lite-gray: #F2F2F2;
$light-gray-input-bg: #F8F8F8;
$light-gray-icon-bg: rgba(198, 198, 198, 0.5);
$light-gray-some-bg: rgba(0, 0, 0, 0.05);
$border-color: rgba(0, 0, 0, 0.24);
$gray: rgba(0, 0, 0, 0.42);
$dark-gray: rgba(0, 0, 0, 0.64);
$black: #3A3A3A;
$black-bold: rgba(0, 0, 0, 0.8);
$violet: #1A65DA;
$light-violet: #F9F9FB;
$hover-white-block: #EBEBEB;
$dark-blue: #14A0EF;
$light-blue: rgba(26, 114, 218, 0.1);
$hover-light-blue: rgba(26, 114, 218, 0.2);
$lite-blue-16per: rgba(26, 114, 218, 0.16);
$white: #fff;
$green: #219653;
$lite-green: rgba(29, 159, 85, 0.16);
$green20per: rgba(29, 159, 85, 0.2);
$red: #A45656;
$dark-red: #D65555;
$light-red: #F0E1E3;
$red-transparent: rgba(210, 53, 53, 0.4);
$red-background: rgba(214, 85, 85, 0.16);
$pink-background: #F8E4E4;
$red20per: rgba(214, 85, 85, 0.2);
$mobile-red: #CE6262;
$lite-red: #F0E1E3;
$yellow: #C58E00;
$yellowtrans: rgba(219, 118, 0, 0.2);
$lite-yellow: #F4E8C7;
$background-hover-secondary: #175BC4;
$background-color-hover-searching: rgba(0, 0, 0, 0.12);
$notificationColor: #464A4D;
$notificationButtonColor: #59A5D0;
$disabledColorText: rgba(0, 0, 0, 0.64);
$activityHeader: rgba(0, 0, 0, 0.32);
$grays-white10per: rgba(255, 255, 255, 0.1);
$grays-white54per: rgba(255, 255, 255, 0.54);
$hover-gray: #ECECEE;
$onlain: #1D9F55;
$perfectBlue: #D1E3F8;
$green-trans: rgba(69, 194, 123, 0.2);
$green-two: #1D9F55;
$blue-ligth: #E8F1FB;
$black-bg-tooltip: #3C4044;
$gray1: #F9F9FB;
$gray2: #F2F2F2;
$gray3: #E2E2E2;
$gray4: #C2C2C2;
$gray5: #949494;
$gray6: #5C5C5C;
$light-yellow: #FBF1D8;
$light-red: #FDEAEA;
$light-green: #DCF9E9;
$lite-dark-2per: rgba(0, 0, 0, 0.2);
$docs-modal-bg: rgba(51, 51, 51, 0.5);
$blue-ligth-2: #D4E4F9;
$blue-light-2-template: rgba(212, 228, 249, 0.5);
$blue-light-3-template: rgba(212, 228, 249, 1);
$white-90per: rgba(255, 255, 255, 0.9);
$yellow-status-color: #F4E6C0;
$yellow-status-color-border: #C58E00;
$gray-black: #404040;
$statusBrPending:#E2C680;
$statusBrApprove:#80C19C;
$statusBrReject:#EAAAAA;
$previewAttachHover:rgba(51, 51, 51, 0.4);

//Border
$borderRadiusButton: 0.4rem;
$borderRadius: 0.8rem;
$borderRaduisMid: 0.6rem;
$bigBorderRadius: 1.6rem;
$veryBigBorderRadius: 2rem; // :D
$borderStyle: 0.1rem solid $border-color;
$border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
$boxShadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
$boxShadow34per: 0px 19px 69px rgba(0, 0, 0, 0.34);
$notificationBoxShadow: 0px 4px 11px rgba(0, 0, 0, 0.15);

//Input
$inputHeight: 5rem;

//Button
$buttonHeight: 5rem;

//Padding and margin(indent)
$indent_xxs: 0.4rem;
$indent_xs: 0.8rem;
$indent_ssm: 1.2rem;
$indent_s: 1.6rem;
$indent_sm: 2.4rem;
$indent_m: 3.2rem;
$indent_l: 4.8rem;
$indent_xl: 6.4rem;
$indent_xxl: 7.2rem;

//Font-size 
$font-size_xxs: 1.2rem;
$font-size_xss: 1.4rem;
$font-size_xs: 1.6rem;
$font-size_s: 1.8rem;
$font-size_m: 2rem;
$font-size_l: 2.4rem;
$font-size-mobile_s: 1.4rem;
$font-size-h1: 3.6rem;
$line-height-h1: 4.2rem;

//Font-family
$font-family-bold: 'Montserrat Bold';
$font-family-normal: 'Montserrat Medium';


@mixin container-col {
  display: flex;
  flex-direction: column;
}

//Export to JS
:export {
  violet: $violet;
  gray: $gray;
  darkGray: $dark-gray;
  lightGray: $lite-gray;
  lightGrayInput: $light-gray-input-bg;
  white: $white;
  darkRed: $dark-red;
  redTransparent: $red-transparent;
  disabledColorText: $disabledColorText;
  yellow: $yellow;
}