.left-sidebar-support {
  height: 5.6rem;
  width: 24.8rem;
  margin: 1.6rem 1.6rem 0 1.6rem;
  display: flex;
  justify-content: space-between;
  border-radius: 3rem 3rem 0 0;
  box-shadow: 0 -0.2rem 1.4rem 0 rgba(31, 74, 113, 0.11);
  font-family: "Nunito Sans";
  position: fixed;
  z-index: 5000;
  bottom: 0rem;
  transition: all 0.4s ease-in-out;

  &-content {
    display: flex;
    width: 63%;
    padding: 0.8rem 0 0.8rem 1.2rem;
    transition: 0.3s linear;

    .support-icon {
      flex-shrink: 0;
      margin: auto 0;
      margin-left: auto;
    }

    .support-info {
      transition: all 0.3s ease-in-out;
      margin-left: 1.6rem;
      margin-right: auto;
      overflow: hidden;
      opacity: 1;

      .support-title {
        color: #949494;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .support-link {
        display: block;
        font-family: Nunito Sans;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
          color: #1a65da;
        }
      }
    }
  }

  &.close {
    margin: 1.6rem 0.8rem 0 0.8rem;
    padding-left: 1.2rem;

    width: 12rem;

    &:hover {
      cursor: pointer;
    }
    .left-sidebar-support-content {

      width: 0;
    }

    .support-info {
      overflow: hidden;
      opacity: 0;
      margin-left: 0;
    }
  }
}

.left-sidebar-support-popup {
  overflow: hidden;
  bottom: 5.4rem;
  left: 0.9rem;
  z-index: 2001;
  position: absolute;
  padding: 1.6rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.4rem 1.4rem 0 rgba(0, 0, 0, 0.06);
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  font-family: "Nunito Sans";

  &.open {
    transform: scale(1);
  }

  .support-title {
    color: #949494;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .support-link {
    font-size: 1.6rem;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
      color: #1a65da;
    }
  }
}