@import '../../../../shared/style/variables.scss';

.file-upload-card {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem dashed $violet;
  border-radius: $borderRadiusButton;
  cursor: pointer;

  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
      font-size: $font-size_xs;
      color: $violet;
      margin-bottom: 0;
    }
  }
}