.role-info-matrix {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-left: 0.4rem;


      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis
    }

    svg {
      flex-shrink: 0;
    }
  } 
}


.role-name-tooltip{
  .ant-tooltip-content {
    border-radius: 0.8rem;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      font-family: 'Nunito Sans';
      font-size: 1.4rem;
      line-height: 2.4rem;
      background-color: #3C4044;
      border-radius: 0.6rem;
      color: #FFFFFF;
      text-align: center;
      padding: 0.8rem 1.6rem;
    }
  }
}