@import '../../../../styles/variables';
@import '../../../../styles/mixins.scss';

.project-button {
  @include row-y-center;
  height: 6.8rem;
  margin: 0 1.6rem;
  margin-top: auto;
  margin-bottom: 7.2rem;
  padding: 1.6rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  border: 0.1rem solid #E2E2E2;
  transition: all 0.3s ease-in-out;
  position: relative;

  &.hover {
    background-color: #F2F2F2;
  }

  .project{
    flex-shrink: 0;
  }

  .projects-invitations {
    flex-shrink: 0;
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.2rem;
    background-color: #D65555;
    border-radius: 50%;
    color: #FFFFFF;
    display: none;

    &.blinker {
      display: grid;
      place-items: center;
      animation-name: blinker;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(1.0,0,0,1.0);
      animation-duration: 1.5s;
      -webkit-animation-name: blinker;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
      -webkit-animation-duration: 1.5s;
    }

    @keyframes blinker {
      from {
        opacity: 1.0;
      }
      to {
        opacity: 0.0;
      }
    }

    @-webkit-keyframes blinker {
      from {
        opacity: 1.0;
      }
      to {
        opacity: 0.0;
      }
    }
  }

  .chevron {
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
    transform: rotate(180deg);

    &.close {
      opacity: 0;
    }

    &.active {
      transform: rotate(0deg);

      path {
        fill: #1A65DA;
      }
    }

    &.hidden {
      display: none;
    }

  }

  .suffix_icon {
    margin-left: 1.6rem;
    padding: 1.4rem 0.8rem;
    border-radius: 1rem;
    flex-shrink: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.21s;
    &:hover {
      background-color: #F2F2F2;
    }

    &.close {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transition-delay: 0s;
    }
  }

  &__main {
    @include row-y-center;
    width: 100%;
    font-family: "Nunito Sans";

    &_info {
      margin-left: 1.6rem;
      width: 10rem;
      overflow: hidden;
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &.new-project {
        width: 14rem;
      }

      .title {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $gray5;
        text-overflow: ellipsis;
        text-align: start;
        overflow: hidden;
        white-space: nowrap;
      }

      .name {
        white-space: nowrap;
        font-size: 1.6rem;
        line-height: 1.8rem;
        text-overflow: ellipsis;
        text-align: start;
        overflow: hidden;

        &.new-project {
          color: #1a65da;
          font-weight: 800;
        }
      }
    }
  }

  &.close {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;

    .project {
      margin: 0 auto;
    }

    .project-button__main{
      overflow: hidden;

      &_info{
        width: 0rem;
        margin-left: 0rem;
        opacity: 0;
      }
    }
  }

  &.active {
    border-color: #1A65DA;
  }
  &.close.active {
    background-color: #E8F1FB;
    border-color: #E8F1FB;
  }

  .folded-icon {
    left: 2.4rem;
    top: 5.4rem;
  }
}

.settings-btn {
  border: 0.1rem solid #E2E2E2;
  border-radius: 1rem;
  max-width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 0.8rem 0.6rem 0;
  transition: all 0.3s;
  height: 0;
  opacity: 0;
  
  &:hover {
    background-color: $light-gray-some-bg;
    cursor: pointer;
  }

  &.close {
    margin-bottom: 7rem;
    height: 4rem;
    opacity: 1;
  }
}