.table-row-user {
  width: 100%;
  height: 9.6rem;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &_name,
  &_company,
  &_position {
    width: 25%;
    margin-left: 3.2rem;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;

    color: #5C5C5C
  }

  &_name {
    font-weight: 700;
    color: #404040
  }

  &_company,&_position {
    font-weight: 400;
  }

  &_line{
    position: absolute;
    height: 0.1rem;
    background-color: #E2E2E2;
    width: calc(100% - 8rem);
    bottom: 0;
    left: 8rem;
  }
}