.ant-modal-wrap {
  transform: scale(0.8);
  top: -15vh !important;
  bottom: -15vh !important;
}

.modal-with-antd {
  font-family: "Nunito Sans";

  .ant-modal-content {
    border-radius: 1.2rem;
  }

  .ant-modal-title {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-header {
    padding-top: 3.2rem;
    border-radius: 1.2rem;
    border: none;
    padding-bottom: 0;
  }

  .ant-modal-body {
    padding: 3.2rem;
  }

  .ant-modal-footer {
    padding: 3.2rem;
    padding-top: 0;
    display: flex;
    justify-content: end;
    border-top: none;

    .button_blue-ligth {
      margin-right: 1.6rem;
    }
  }

  .ant-modal-title {
    font-size: 2.4rem;
    font-weight: 800;
  }
}

//todo разобраться со стилями
//.ant-modal-content {
//  height: 100%;
//  overflow: hidden;
//  display: flex;
//  flex-direction: column;
//  flex-grow: 0;
//}

//.ant-modal-body {
//  height: 100%;
//  display: flex;
//  flex-direction: column;
//  overflow: hidden;
//}

// .ant-modal-mask {
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
// }

// .ant-modal-close {
//   display: none;
// }

// .modal-with-antd {
//   background-color: white;
//   padding: 3.2rem;
//   border-radius: 1.2rem;
// }
