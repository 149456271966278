.project-invitation-reject-modal {
  font-family: 'Nunito Sans';

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
  }

  .block__header {
    display: none;
  }

  .custom-textarea {
    height: 18rem;
    max-height: 18rem;
    textarea {
      height: 16px !important;
    }

    .custom-textarea__count-symbol {
      display: block;
    }
  }

  .text-area-label {
    top: 15%;
  }

  .custom-textarea__count-symbol {
    bottom: -2rem;
    right: 0.6rem;
  }

  .btn__send {
    margin-top: 3.2rem;
    margin-left: auto;
  }
}