@import '../../style/variables.scss';

.hover-container{
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  &:hover {
    cursor: pointer;
  }
  &.active .hover-item{
    opacity: 1;
  }
  &.disable .hover-item{
    opacity: 0 !important;
  }
  .hover-item{
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    background-color: $lite-gray;
    border-radius: 50%;
    left: -50%;
    top: -50%;
    opacity: 0;
    transition: all 0.2s;
  }
  &:hover .hover-item{
    opacity: 1;
  }
  .hover-child{
    position: absolute;
  }
}