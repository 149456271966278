@import "./src/(deprecated)/styles/variables";

.template-field {
  .custom-textarea {
    padding: 1.2rem 1.6rem;
    min-height: 5rem;
    padding-right: 5.5rem;

    textarea {
      max-height: 13rem;
      padding-bottom: 0;
    }

    .hover-container {
      position: absolute;
      right: 1.3rem;
      top: 1.2rem;
    }

    .custom-textarea__count-symbol {
      display: none;
    }
  }
}
